import { LiteClient, liteClient } from "algoliasearch/lite";

const algoliaAppId: string =
  process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID ?? "";
const algoliaApiKey: string =
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_ONLY_API_KEY ?? "";
const bookIndex: string = process.env.NEXT_PUBLIC_ALGOLIA_BOOK_INDEX ?? "";
const authorIndex: string = process.env.NEXT_PUBLIC_ALGOLIA_AUTHOR_INDEX ?? "";
const querySuggestionsIndex: string =
  process.env.NEXT_PUBLIC_ALGOLIA_QUERY_SUGGESTIONS_INDEX ?? "";

const searchClient: LiteClient = liteClient(algoliaAppId, algoliaApiKey);

export { searchClient, bookIndex, authorIndex, querySuggestionsIndex };
