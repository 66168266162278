"use client";
import { bookIndex, searchClient } from "@/search";
import React from "react";
import { InstantSearch } from "react-instantsearch";

type Props = {
  children: React.ReactNode;
};

const InstantSearchConfig = ({ children }: Props) => {
  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={bookIndex}
      insights
      routing={false} // Disable Algolia InstantSearch routing since handling routing manually
      future={{
        preserveSharedStateOnUnmount: true,
      }}
    >
      {children}
    </InstantSearch>
  );
};

export default InstantSearchConfig;
